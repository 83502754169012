export const email = /^.+@.+[.]\w+/

// At least 8 characters, use letters AND numbers/special characters
export const password = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[a-zA-Z]).{6,}$/

export const postalCode = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i

export const phone = /^(\+\d{2}|00\d{2}|\d{2})\d{5,15}$/

export const date =
  /(^(((0?[1-9]|1[0-9]|2[0-8])-(0?[1-9]|1[012]))|((29|30|31)-(0?[13578]|1[02]))|((29|30)-(0?[4,6,9]|11)))-(19|[2-9][0-9])\d\d$)|(^29-02-(19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/

export default {
  email,
  password,
  postalCode,
  phone,
  date,
}
